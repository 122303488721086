import * as React from 'react';
import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Slider.scss';

export const forumUrl = 'https://forum.phoenix-rp.de/forum/';
export const forumRegisterUrl =
	'https://forum.phoenix-rp.de/index.php?register/';

export interface ISliderProps {}

type ISliderContent = {
	headline: string;
	subline: string;
	backgroundSrc: string;
	buttonUrl: string;
	buttonText: string;
	buttonFilled?: string;
};

const sliderContents: ISliderContent[] = [
	{
		headline: 'PhoenixRP - GTA 5 Roleplay Community',
		subline:
			'Eine Community, die gelebt werden will. So verstehen und leben wir PhoenixRP. Zögere nicht und beginne dein Abenteuer noch heute, reise ein und lasse dich von deiner neuen Spielewelt leiten.',
		buttonFilled: 'filled',
		buttonText: 'Forum',
		buttonUrl: forumUrl,
		backgroundSrc: 'https://www.phoenix-rp.de/images/bg/slider/pier.png',
	},
	{
		headline: 'Etwas für Jede/n, auch für Dich!',
		subline:
			'Ob Einzelhandel, Wholesale oder extravagante Einzelstücke, das kannst du dir auf PhoenixRP selbst aussuchen. Mit einer breiten Vielfalt an Möglichkeiten, wird die dir niemals langweilig.',
		buttonFilled: 'filled',
		buttonText: 'Registrieren',
		buttonUrl: forumRegisterUrl,
		backgroundSrc: 'https://www.phoenix-rp.de/images/bg/slider/disco.png',
	},
	{
		headline: 'Werde Officer oder Medic',
		subline:
			'Verbringe unvergessliche Momente im Dienst mit deinen Kollegen und sorge für Sicherheit und Ordnung in Los Santos. Unsere Fraktionen sind bei Tag und Nacht unterwegs.',
		buttonFilled: 'filled',
		buttonText: 'Registrieren',
		buttonUrl: forumRegisterUrl,
		backgroundSrc: 'https://www.phoenix-rp.de/images/bg/slider/box.png',
	},
	{
		headline: 'Fast unbegrenzte Möglichkeiten',
		subline:
			'Tauche in deine Welt und sei einfach Mal jemanden Anders. Lebe deine Träume und werde, wen du schon immer sein wolltest. Jetzt ist deine Zeit.',
		buttonFilled: 'filled',
		buttonText: 'Registrieren',
		buttonUrl: forumRegisterUrl,
		backgroundSrc: 'https://www.phoenix-rp.de/images/bg/slider/mountain.png',
	},
	{
		headline: 'Updates, Erweiterungen und neue Systeme',
		subline:
			'Unser Gamedesign und Entwicklungs-Team erweitert unsere Plattform stetig weiter. Neue Klamotten oder Systeme, mit vollem Elan bauen wir dir eine neue Welt.',
		buttonFilled: 'filled',
		buttonText: 'Forum',
		buttonUrl: forumUrl,
		backgroundSrc: 'https://www.phoenix-rp.de/images/bg/slider/xmas.png',
	},
];

export default function Slider(props: ISliderProps) {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Carousel
			autoPlay={true}
			interval={5000}
			infiniteLoop={true}
			showStatus={false}
			showIndicators={false}
			showThumbs={false}
			className="slider"
		>
			{sliderContents.map((item) => {
				return (
					<div
						className={`slide-wrapper ${!md ? 'mobile' : ''}`}
						style={{
							backgroundImage: `url(${item.backgroundSrc})`,
						}}
					>
						<Container>
							<div className="body">
								<p className="headline">{item.headline}</p>
								<p className="subline">{item.subline}</p>
								<p className="button">
									<a
										className={`button-secondary ${item.buttonFilled}`}
										href={item.buttonUrl}
									>
										{item.buttonText}
									</a>
								</p>
							</div>
						</Container>
					</div>
				);
			})}
		</Carousel>
	);
}
