import * as React from 'react';
import Features, { Feature } from '../Features/Features';
import ImageText from '../ImageText/ImageText';
import JoinNow from '../JoinNow/JoinNow';
import Navigation from '../Navigation/Navigation';
import Slider from '../Slider/Slider';
import TextImage from '../TextImage/TextImage';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Footer from '../Footer/Footer';
import Social from '../Social/Social';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBuilding,
	faCarSide,
	faCouch,
} from '@fortawesome/free-solid-svg-icons';

library.add(faCarSide);
library.add(faBuilding);
library.add(faCouch);

export const forumUrl = 'https://forum.phoenix-rp.de/forum/';
export const forumRegisterUrl =
	'https://forum.phoenix-rp.de/index.php?register/';

export const facebookUrl =
	'https://www.facebook.com/Phoenix-RP-SocialMedia-101948905376332/';
export const youtubeUrl =
	'https://www.youtube.com/channel/UCPwMCaczpchf8sHWE6GiJig';
export const twitchUrl = 'https://www.twitch.tv/phoenixrp_de';
export const twitchTeamUrl = 'https://www.twitch.tv/team/phoenix-rp';
export const twitterUrl = 'https://twitter.com/phoenixrp_de?lang=de';
export const discordUrl = 'https://discord.gg/YKybedA';

export interface ILayoutProps {}

export default function Layout(props: ILayoutProps) {
	return (
		<div className="layout">
			<Navigation />

			<Slider />

			<div id="community">
				<Features headline="Trete der Community bei" align="left">
					<Feature
						headline="1. Registration"
						subline="Registriere dich im Forum und erhalte Zugriff alle interessanten Themen und sei ein Teil der Community."
					/>
					<Feature
						headline="2. Charakter Erstellung"
						subline="Erstelle dir einen Charakter mit deinem Wunschnamen und Hintergrundgeschichte."
					/>
					<Feature
						headline="3. Whitelist"
						subline="Nimm die Reise nach Los Santos in Angriff und durchlaufe unser Whitelist-Verfahren."
					/>
				</Features>
			</div>

			<JoinNow
				label="Registrieren"
				to={forumRegisterUrl}
				imageSrc="https://www.phoenix-rp.de/images/slider-10.jpeg"
			/>

			<div
				id="roleplay"
				style={{
					backgroundImage: 'url(https://www.phoenix-rp.de/images/bg/2.png)',
				}}
			>
				<ImageText
					imageSrc="https://www.phoenix-rp.de/images/bg/slider/town.jpg"
					headline={
						<React.Fragment>Unsere Insel - dein Zuhause</React.Fragment>
					}
					subline={
						<React.Fragment>
							Einzigartige Landschaften prägen das Bild der Insel, egal ob im
							staubigen Sandy Shores oder im urbanen Los Santos. Liebevolle
							Details, Waldstücke und geschickt platzierte Gebäude und
							Häusergruppen machen das Erscheinungsbild zu etwas ganz
							Besonderem. Und trotzdem bleibt dieses Gerüst so wandelbar wie das
							Wetter. An welchem Ort das Leben tobt, wo das beste Essen genossen
							wird oder in welchem Abschnitt sich die Tuningszene trifft, das
							allein bestimmst du. In welche Richtung wird dich also dein Weg
							führen? Arbeiter oder Luxusboutiquebesitzer? Fahrlehrer oder
							Officer? Leben retten im Dienste der Medizin oder Leben in Angst
							und Schrecken versetzen als Anführer einer einflussreichen
							Gruppierung? Du hast die Wahl!
						</React.Fragment>
					}
				/>

				<TextImage
					imageSrc="https://www.phoenix-rp.de/images/bg/slider/industrial.png"
					headline={
						<React.Fragment>
							Economy - ohne dich läuft hier gar nichts!
						</React.Fragment>
					}
					subline={
						<React.Fragment>
							Egal für welchen Weg du dich entscheidest, deine Taten und deine
							Arbeit haben Auswirkungen auf das Leben aller im Staat. Wie große
							und kleine Zahnräder greifen hier alle Geschäftszweige ineinander
							und ergeben ein großes Ganzes. Egal ob in der
							Lebensmittelproduktion, dem Fertigen von Autos oder der
							Herstellung der glanzvollen Schmuckstücke um deinen Hals. In jedem
							Gegenstand stecken viele Stunden Arbeit der fleißigen Einwohner -
							auch deine?!
						</React.Fragment>
					}
				/>
			</div>

			<br />

			<Social />

			<div id="features">
				<Features
					headline="Features"
					align="center"
					backgroundImage="https://www.phoenix-rp.de/images/bg/alpha/2.png"
				>
					<Feature
						headline="Banküberfälle"
						subline="Reicht das Kleingeld aus den Shops nicht mehr, um deinen aufwändigen Lebensstil zu finanzieren? Du und deine Kumpels benötigen dringend Geld, um sich aus einer misslichen Lage zu befreien? Vielleicht wird es Zeit, mal der Staatsbank einen Besuch abzustatten…"
						icon={<AccountBalanceIcon fontSize="inherit" />}
					/>
					<Feature
						headline="Universität"
						subline="Du bist immer fleißig und für deinen Chef ein wahrer Segen. Aber irgendwie hast du das Gefühl, dass die anderen schneller, besser, effizienter sind als du. Eventuell lohnt es sich, etwas Zeit und Mühe in deine Ausbildung zu investieren und nach einem geeigneten Studiengang zu suchen."
						icon={<AccountBalanceIcon fontSize="inherit" />}
					/>
					<Feature
						headline="Verwahrstelle und Asservatenkammer"
						subline="Das letzte Ticket war eins zu viel - da steht es nun, dein schönes Auto, zwischen all den anderen, die die Cops einkassiert und stillgelegt haben, nur weil sie der Meinung sind, sie könnten dir erzählen wie du zu fahren hast. Und deinen Führerschein haben sie auch direkt einbehalten. Ob es wohl die Möglichkeit gibt, sich die Sachen wiederzubeschaffen?"
						icon={<AccountBalanceIcon fontSize="inherit" />}
					/>
					<Feature
						headline="Fahren und Fliegen"
						subline="Mobil sein ist wunderbar! Aber wieso immer nur auf der Straße? Wie wäre es mal mit einem Helikopterrundflug über Los Santos? Oder mit einer Bootstour? Für all diese Belange steht dir das Department of MotorVehicles stets zur Verfügung. Und falls du eher der sportliche Typ bist, dann schnapp dir doch ein Fahrrad und erkunde deine Umgebung."
						icon={<AccountBalanceIcon fontSize="inherit" />}
					/>
					<Feature
						headline="Einzigartige Systeme"
						subline="Alles wächst. Egal ob auf dem Feld oder auf deinem Kopf. Dynamische Systeme sorgen dafür, dass selbst das Farming nicht langweilig wird. Aber Achtung: wenn du nicht auf wuchernde Überraschungen auf deinem Kopf stehst, solltest du ab und an den Friseur besuchen."
						icon={<AccountBalanceIcon fontSize="inherit" />}
					/>
					<Feature
						headline="Los Santos Medical und Police Department"
						subline="Die Damen und Herren der beiden Fraktionen sind Tag und Nacht im Einsatz, um den Bewohnern der Insel mit Rat und Tat zur Seite zu stehen. Werde ein Teil von ihnen und unterstütze sie tatkräftig. "
						icon={<AccountBalanceIcon fontSize="inherit" />}
					/>
				</Features>
			</div>

			<div id="modding">
				<Features headline="Modding" align="left">
					<Feature
						headline="Fahrzeuge"
						subline="Vom Oldtimer bis zum Supersportler, von der Honda bis zum Lamborghini - hier findest du alles, was dein motorisiertes Herz höher schlagen lässt!"
						icon={<FontAwesomeIcon icon={['fas', 'car-side']} />}
					/>
					<Feature
						headline="Gebäude"
						subline="Auf der ganzen Insel finden sich verschiedenste, interessante Gebäude. Vom imposanten Justizgebäude bis zur kleinen Gangsterhütte in der Grovestreet. Es gibt viel zu entdecken."
						icon={<FontAwesomeIcon icon={['fas', 'building']} />}
					/>
					<Feature
						headline="Wohnungen"
						subline="Aufgeschlossen und hereinspaziert - die Wohnungen und Häuser bestechen mit individuell auswählbaren Interieurs. So kannst du deinem Zuhause deine individuelle Note verleihen."
						icon={<FontAwesomeIcon icon={['fas', 'couch']} />}
					/>
				</Features>
			</div>

			<JoinNow
				label="Registrieren"
				to={forumRegisterUrl}
				imageSrc="https://www.phoenix-rp.de/images/bg/register.jpg"
			/>

			<div id="team">
				<Features headline="Das Team" align="left">
					<Feature
						headline="Leitung"
						subline="Zwei Teammitglieder, engagiert und stets um die Community bemüht. Offen für Neues, Vorschläge und natürlich auch bittere Kritik."
					/>
					<Feature
						headline="Team"
						subline="Ohne das gesamte Team würde hier nicht viel passieren. Jede/r Einzelne/r trägt zum Resultat hinzu, egal ob Support, Game Design, Entwicklung, Sekretariat oder Verwaltung."
					/>
					<Feature
						headline="Entwicklung"
						subline="Mit einer handvoll motivierten Entwicklern lassen sich bereits grosse Ideen umsetzen. Motorschaden oder zufällige Events, Grenzen kennen wir keine."
					/>
				</Features>
			</div>

			<JoinNow
				label="Werde Teil des Teams"
				to={forumRegisterUrl}
				imageSrc="https://www.phoenix-rp.de/images/slider-3.jpeg"
			/>

			<Footer />
		</div>
	);
}
