import * as React from 'react';
import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Navigation.scss';
import { forumRegisterUrl } from '../Layout/Layout';

library.add(faBars);
library.add(faTimes);

export interface INavigationProps {}

export default function Navigation(props: INavigationProps) {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			{!md && (
				<div className={`sidebar ${open ? 'active' : ''}`}>
					<div className="header">
						<img
							className="logo"
							src="https://forum.phoenix-rp.de/images/styleLogo-1d8f06a31e5dd8b1a099a85ad6b10579ec24b875.png"
							alt=""
						/>
						<FontAwesomeIcon
							className="close"
							icon={['fas', 'times']}
							onClick={() => setOpen(!open)}
						/>
					</div>
					<div className="body">
						<ul>
							<li>
								<a href="https://forum.phoenix-rp.de/forum/">Forum</a>
							</li>
							<li>
								<a href="#community">Community</a>
							</li>
							<li>
								<a href="#roleplay">Roleplay</a>
							</li>
							<li>
								<a href="#features">Features</a>
							</li>
							<li>
								<a href="#modding">Modding</a>
							</li>
							<li>
								<a href="#team">Team</a>
							</li>
						</ul>
						<a className="button button-secondary" href={forumRegisterUrl}>
							Registrieren
						</a>
						<img
							className="avoro"
							src="https://forum.phoenix-rp.de/images/phoenixrp/avoro_big.png"
							alt="Avoro"
						/>
					</div>
				</div>
			)}
			<div className="navigation">
				<Container>
					{md ? (
						<ul className="left">
							<li className="logo">
								<img
									src="https://forum.phoenix-rp.de/images/styleLogo-1d8f06a31e5dd8b1a099a85ad6b10579ec24b875.png"
									alt=""
								/>
							</li>
							<li>
								<a href="https://forum.phoenix-rp.de/forum/">Forum</a>
							</li>
							<li>
								<a href="#community">Community</a>
							</li>
							<li>
								<a href="#roleplay">Roleplay</a>
							</li>
							<li>
								<a href="#features">Features</a>
							</li>
							<li>
								<a href="#modding">Modding</a>
							</li>
							<li>
								<a href="#team">Team</a>
							</li>
						</ul>
					) : (
						<ul className="left">
							<li className="logo inline">
								<img
									src="https://forum.phoenix-rp.de/images/styleLogo-1d8f06a31e5dd8b1a099a85ad6b10579ec24b875.png"
									alt=""
								/>
							</li>
						</ul>
					)}
					<ul className="right">
						{md ? (
							<React.Fragment>
								<li>
									<a
										href="https://www.avoro.eu"
										rel="noreferrer"
										target="_blank"
									>
										<img
											className="avoro"
											src="https://forum.phoenix-rp.de/images/phoenixrp/avoro_big.png"
											alt="Avoro"
										/>
									</a>
								</li>
								<li>
									<span className="button button-secondary">Registrieren</span>
								</li>
							</React.Fragment>
						) : (
							<li className="toggleSidebar">
								<FontAwesomeIcon
									icon={['fas', 'bars']}
									onClick={() => setOpen(!open)}
								/>
							</li>
						)}
					</ul>
				</Container>
			</div>
		</React.Fragment>
	);
}
