import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import * as React from 'react';
import './Features.scss';

export interface IFeaturesProps {
	children: React.ReactNode;
	backgroundImage?: string;
	align: 'center' | 'left' | 'right';
	headline?: string;
	color?: string;
}

export default function Features(props: IFeaturesProps) {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<div
			className={`features-wrapper ${props.color || ''} ${!md ? 'mobile' : ''}`}
			style={{ backgroundImage: `url(${props.backgroundImage || ''})` }}
		>
			<Container className={`features features-${props.align}`}>
				<Grid container spacing={4} direction="row">
					{props.headline && (
						<Grid item xs={12}>
							<div className="title">{props.headline}</div>
						</Grid>
					)}
					{props.children}
				</Grid>
			</Container>
		</div>
	);
}

export interface IFeatureProps {
	headline: string;
	subline: string;
	icon?: React.ReactNode;
}

export function Feature(props: IFeatureProps) {
	return (
		<Grid item xs={12} md={4}>
			<div className="feature-wrapper">
				<div className="feature">
					{props.icon && <p className="icon">{props.icon}</p>}
					<p className="headline">{props.headline}</p>
					<p className="subline">{props.subline}</p>
				</div>
			</div>
		</Grid>
	);
}
