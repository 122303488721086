import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import * as React from 'react';
import './TextImage.scss';

export interface ITextImageProps {
	imageSrc: string;
	backgroundSrc?: string;
	headline: React.ReactNode;
	subline: React.ReactNode;
}

export default function TextImage(props: ITextImageProps) {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<div
			className="text-image"
			style={{ backgroundImage: `url(${props.backgroundSrc || ''})` }}
		>
			{!md && (
				<img
					className="image"
					style={{ width: '100%' }}
					src={props.imageSrc}
					alt=""
				/>
			)}
			<Container style={{ zIndex: 2 }}>
				<Grid container spacing={4}>
					<Grid item xs={12} md={5}>
						<div className="text">
							<p className="headline">{props.headline}</p>
							<p className="subline">{props.subline}</p>
						</div>
					</Grid>
					{md && (
						<Grid item md={7}>
							<img
								className="image"
								style={{ width: '100%' }}
								src={props.imageSrc}
								alt=""
							/>
						</Grid>
					)}
				</Grid>
			</Container>
		</div>
	);
}
