import * as React from "react";
import "./JoinNow.scss";

export interface IJoinNowProps {
  imageSrc: string;
  label: string;
  to: string;
}

export default function JoinNow(props: IJoinNowProps) {
  return (
    <div
      className="join-now"
      style={{ backgroundImage: `url(${props.imageSrc})` }}
    >
      <a className="button-secondary filled button-large" href={props.to}>
        {props.label}
      </a>
    </div>
  );
}
