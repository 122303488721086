import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import * as React from 'react';
import './Footer.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDiscord,
	faFacebook,
	faTwitterSquare,
	faTwitch,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
	discordUrl,
	facebookUrl,
	twitchTeamUrl,
	twitterUrl,
	youtubeUrl,
} from '../Layout/Layout';

library.add(faFacebook);
library.add(faDiscord);
library.add(faTwitterSquare);
library.add(faTwitch);
library.add(faYoutube);

export interface IFooterProps {}

export default function Footer(props: IFooterProps) {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<div className="footer-wrapper">
			<Container>
				<div className="footer">
					<Grid container spacing={4}>
						<Grid item xs={12} md={4}>
							<div className={`left ${md ? 'md' : ''}`}>
								&copy; 2018 - {new Date().getFullYear()} Phoenix-RP
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div className={`center ${md ? 'md' : ''}`}>
								<a
									className="icon"
									href={twitchTeamUrl}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'twitch']} />
								</a>
								<a
									className="icon"
									href={discordUrl}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'discord']} />
								</a>
								<a
									className="icon"
									href={youtubeUrl}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'youtube']} />
								</a>
								<a
									className="icon"
									href={twitterUrl}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'twitter-square']} />
								</a>
								<a
									className="icon"
									href={facebookUrl}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'facebook']} />
								</a>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div className={`right ${md ? 'md' : ''}`}>
								<a href="https://forum.phoenix-rp.de/index.php?datenschutzerklaerung/">
									Datenschutz
								</a>
								<a href="https://forum.phoenix-rp.de/index.php?legal-notice/">
									Impressum
								</a>
								<a href="https://forum.phoenix-rp.de/index.php?contact/">
									Kontakt
								</a>
							</div>
						</Grid>
					</Grid>
				</div>
			</Container>
		</div>
	);
}
