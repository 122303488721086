import * as React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDiscord,
	faFacebook,
	faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import './Social.scss';
import {
	discordUrl,
	facebookUrl,
	twitchTeamUrl,
	twitterUrl,
	youtubeUrl,
} from '../Layout/Layout';

library.add(faFacebook);
library.add(faDiscord);
library.add(faTwitterSquare);

export interface ISocialProps {}

export default function Social(props: ISocialProps) {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Container className={`social ${!md ? 'mobile' : ''}`}>
			<div className="headline">Folge uns auf</div>
			<div className="icons">
				<div className="icon">
					<a href={twitchTeamUrl} target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={['fab', 'twitch']} />
					</a>
				</div>
				<div className="icon">
					<a href={discordUrl} target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={['fab', 'discord']} />
					</a>
				</div>
				<div className="icon">
					<a href={youtubeUrl} target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={['fab', 'youtube']} />
					</a>
				</div>
				<div className="icon">
					<a href={twitterUrl} target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={['fab', 'twitter-square']} />
					</a>
				</div>
				<div className="icon">
					<a href={facebookUrl} target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={['fab', 'facebook']} />
					</a>
				</div>
			</div>
		</Container>
	);
}
